.container-itinerary {
  width: 100%;
}

.container-itinerary ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.trip-summary {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #bbf7d0;
  border-radius: 12px;
}

.trip-summary div {
  text-align: center;
}

.container-stop {
  padding: 1rem;
  display: flex;
  /* border: 2px solid rgb(0, 0, 0); */
  background-color: #cffafe;
  margin-top: 1rem;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.container-stop i {
  font-size: 2rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 50%;
}

.place {
  text-align: center;
}

.loc-charging {
  font-weight: 700;
  margin-top: 0;
}

/* .stop-item:last-child i {
background-color: blue;
} */

.centered {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.loc-pin {
  width: 2rem;
  text-align: center;
}

/* =================== */

.midMid {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 1rem;
}

.midMid1 {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 100px; */
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;
}

.midMid1 ul {
  
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
}

ul li h3 {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
}

p {
  margin: 0.5rem 0 0 0;
}

.topHead {
  width: 30%;
}

.topHead h1 {
  font-size: 35px;
  font-weight: 900;
  margin: 0;
}

@media only screen and (max-width: 1000px) {
  .midMid1 {
    flex-direction: column;
    /* height: fit-content; */
  }

  .topHead {
    width: auto;
  }

  .midMid1 ul {
    flex-direction: column;
    padding: 0;
  }

  li {
    margin: 0.75rem 0;
  }
}