.modalBackdrop {
    position: absolute; 
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
}

.modal {
    position: sticky;
    color: black;
    margin: 2rem auto;
    width: 80%;
    max-width: 600px;
    background: #fff;
    border-radius: 16px;
    padding: 2rem;
}

.modal p {
    font-size: 1.3rem;
    text-align: start;
}

.modal button {
    display: block;
    margin: 0 auto;
}